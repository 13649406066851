<template>
  <div id="q-app">
    <Main />
  </div>
</template>
<script>
import Main from './components/Main.vue'

export default {
  name: 'App',
   components: {
    Main
  }
}
</script>
