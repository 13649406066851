<template>
<div>
  <header class="menuwrapper">
      <div class="logoWrapper">
          <a href="index.html" id="logo" class=""></a>
      </div>
      <div class="menu">
      
          <a href="#projects" class="menuItem">
              <div class="flex flexCenterCenter">
                  <p>Project</p>
              </div>
          </a>
          <a href="#contact" class="menuItem">
              <div class="flex flexCenterCenter">
                  <p>Contact</p>
              </div>
          </a>
      </div>
  </header>

  <article>
      <div class="homeImgWrapper">
          <div class="homeImgText">
              <h1>Software<br>Developement</h1>
              
              <p>We provide our customers with flexible services of high quality at competitive prices 
                  from our Development and Test Centers in Romania. Our competencies include web-based solutions 
                  and portals, mobile solutions as well as embedded systems. Wirtek focuses on building long-term customer partnerships
                  and a win-win relationship that creates added value for our customers.</p>
          </div>
      </div>
      <div class="productServices">
          <div>
              <div>
                  <h4>Software<br>Developement</h4>
                  <hr>
                  <p>Fox jumped the fence</p>
              </div>
          </div>
          <div>
              <div>
                  <h4>Web<br>Applications</h4>
                  <hr>
                  <p>Fox eat the ship</p>
              </div>
          </div>
          <div>
              <div>
                  <h4>Database<br>and networking</h4>
                  <hr>
                  <p>High speed internet via optic fiber</p>
              </div>
          </div>
      </div>
  </article>

  <article id="projects">
      <h6>Projects</h6>
  
      <div class="projectsWrapper">
          <div>
              <a href="https://www.afrisson.com" class="overlayProjectItem">
                  <h6>Africas greatest hits</h6>
                  <p>www.afrisson.com</p>
              </a>
              <img src="img/afrisson.jpg" alt="" style="margin-top:17%">
          </div>
      </div>
  </article>

  <article id="contact"> 
      <h6>Contact</h6>
      <p class="contactMessage">To contact SevenTech please complete the form.
          If you would like us to call you please include your telephone number. 
          We aim to respond by email within 48 hours. 
          </p>
      <div class="contactWrapper">
          <form method="post" action="">
              <label for="name">Full Name:</label>
              <input id="name" type="text">

              <label for="email">E-mail:</label>
              <input id="email" type="email">

              <label for="subject">Subject:</label>
              <input id="subject" type="text">

              <label for="subject">Your message:</label>
              <textarea></textarea>
              
              <input type="button" class="generalButton buttonAction" @click="contact" value="Send" />
          </form>
          <div class="contactImg">
              <img src="img/contactPhone.jpg" alt="">
          </div>
      </div>

  </article>

  <div class="popUpWrapper" v-if="state.showPopup"> 
      <div class="popUp">
          <div class="popUpElements">
              <i class="fas fa-3x fa-times"></i>
              <h3>Not available</h3>
              <p>The contact is not available for time being;</p>
          </div>
          <i class="popUpClose fas fa-times" @click="closePopup"></i>
      </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Main',
  props: {
    msg: String
  },
  data () {
      return { 
          state : {
              showPopup: false
        }} 
  },
  methods: {
      contact () {
          this.state.showPopup = true;
      },
      closePopup () {
          this.state.showPopup = false;
      }
  }
}
</script>
